<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="500px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="品牌名称" prop="pinPaiMC" class="line">
                <el-input v-model="form.pinPaiMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/spgl/ShangPinPinPai";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                pinPaiMC: [required()], // 品牌名称
                tianJiaSJ: [], // 添加时间
                guiShuMenDian: [], // 归属门店
                shiFuShanChu: [], // 是否删除
            }
            this.titlePrefix = '商品品牌';
            this.defaultForm = {
                id: null,
                pinPaiMC: "", // 品牌名称
                tianJiaSJ: "", // 添加时间
                guiShuMenDian: "", // 归属门店
                shiFuShanChu: "", // 是否删除
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>
